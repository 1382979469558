const StoryblokCDNUrl = 'https://a.storyblok.com';

export interface ImageServiceOptions {
  resize?: {
    type: 'static' | 'smart';
    width: number | 'auto';
    height: number | 'auto';
  };
  filters?: ('grayscale()' | string)[];
  fitIn?: boolean;
}

/**
 * Convenience function to use the Storyblok Image Service
 * to manipulate images.
 *
 * Docs: https://www.storyblok.com/docs/image-service/#documentation
 */
export function useStoryblokImageService(src: string, options: ImageServiceOptions = {}): string {
  if (!src.startsWith(StoryblokCDNUrl) || src.endsWith('.svg')) {
    return src;
  }

  // use this to make the image format adaptive, returning Webp when supported by the browser
  let cdnUrl = `${src}/m/`;

  if (options.resize) {
    cdnUrl += `${options.resize.width === 'auto' ? 0 : options.resize.width}x${
      options.resize.height === 'auto' ? 0 : options.resize.height
    }/`;

    if (options.resize.type === 'smart') cdnUrl += 'smart/';
  }

  if (options.filters) {
    cdnUrl += `filters:${options.filters.join(':')}`;
  }

  if (options.fitIn) {
    cdnUrl += `fit-in/`;
  }

  return cdnUrl;
}
